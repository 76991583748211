.gantt_grid_head_add {
	display: none;
}

div.gantt_cell[data-column-name=add] {
	display: none;
}

.scale_cashBalance {
	height: 200px !important;
}

.brGantt_nestedTask .gantt_add
{
	display: none;
}

.brGantt_rootTask
{
	border-top: solid #cecece 1px;
}

.gantt_task_line
{
	border: none !important;
}

.brGanttMarker_cashBalanceNegative {
	background-color: #ff000030;
	border: solid 1px #ff000060;
	z-index: 0;
}

.gantt_task_content
{
	display: flex;
	justify-content: flex-start !important;
	align-items: center !important;
	padding-left: 6px;
	font-family: 'Rubik';
}

.brGantt_task_delivery {
	background-color: orchid;
	border: none;
	display: flex;
	justify-content: center;
	align-items: center;
}

.brGantt_task_salary {
	background-color: #6986D1;
	border: none;
}

.brGantt_task_income {
	background-color: #00bf8f;
	border: none;
}

.brGantt_task_completed {
	background-color: #56C367 !important;
}

.brGantt_task_expirationWarning {
	background-color: #F1BA2C !important;
}

.brGantt_task_expired {
	background-color: #E95252 !important;
}

.brGantt_task_woDates {
	background-color: silver !important;
}

.brGantt_task_stage {
	font-weight: bold;
}

.brGantt_task_hide {
	display: none;
}

.brGantt_task_job {
	background-color: #6986D1 !important;
}

.brGantt_task_stage {
	background-color: #6986D1 !important;
	border-left: 5px solid #526aa9 !important;
}

.brGantt_container {
	overscroll-behavior: none;
}

.brGantt_cell_balanceNegative {
	background-color: #ff000030;
}

/*
	Стилёчки для работы "хвоста" после задачи (прогнозируемая дата завершения)
 */
.gantt_side_content.gantt_right.gantt_link_crossing
{
	bottom: 0px !important;
}

.gantt_side_content.gantt_right
{
	padding-left: 0;
	position: relative !important;
	top: -27px;
	overflow: visible;
}

.ganttBtn {
	text-transform: none !important;
}
